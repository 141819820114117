import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { mapEdgesToNodes } from '../../utils/helpers';
import loadable from '@loadable/component';
import { getSrc } from 'gatsby-plugin-image';
import Seo from '../../components/common/Seo';

const GraphQLError = loadable(() => import('../../components/error'));
const Layout = loadable(() => import('../../components/blog/Layout'));
const ScliceZone = loadable(() => import('../../containers/blog/single/ScliceZone'));

export const Head = ({ location, params, data, pageContext }) => {
  const {
    prismicBlogPostNew: {
      data: { meta_title, meta_description, meta_keywords, featured_image, author },
    },
  } = data;

  const seo = {
    title: meta_title,
    description: meta_description,
    keywords: meta_keywords || [],
    image: getSrc(featured_image),
    location,
    author: {
      url: author?.url,
      name: author?.document?.data?.name,
    },
  };

  return <Seo {...seo} />;
};

const SingleTempale = memo((props) => {
  const { data, errors, location } = props;

  if (errors) {
    return <GraphQLError errors={errors} />;
  }
  const { prismicBlogPostNew, allPrismicBlogPostNew } = data;
  const latestPosts = mapEdgesToNodes(allPrismicBlogPostNew);
  const {
    uid,
    type,
    tags,
    url,
    data: { h1_header, body, post_name, category_group },
  } = prismicBlogPostNew || {};

  function getLinks(categories) {
    const links = [{ label: post_name, slug: url }];
    if (location?.search) {
      const querySearch = location.search;
      let checkParam = querySearch.indexOf('cuid');
      if (checkParam !== false) {
        const _cuid = querySearch.substring(querySearch.indexOf('=') + 1);

        let _categories = categories.filter(
          ({ category }) => category?.document && category.uid === _cuid
        );

        if (categories.length > 0 && _categories?.length > 0) {
          const {
            category: {
              url,
              document: {
                data: { category_name },
              },
            },
          } = _categories?.[0];
          links.unshift({ label: category_name, slug: url });
        }
      }
    } else if (categories?.length > 0) {
      categories = categories.filter(({ category }) => category?.document);
      const {
        category: {
          url,
          document: {
            data: { category_name, faq_category_name, brand_name, concern_name, ingredient_name },
          },
        },
      } =
        categories?.length > 0 ? categories[0] : { category: { url: '', document: { data: {} } } };

      let _label;

      if (category_name) _label = category_name;
      else if (faq_category_name) _label = faq_category_name;
      else if (brand_name) _label = brand_name;
      else if (concern_name) _label = concern_name;
      else if (ingredient_name) _label = ingredient_name;

      if (_label) links.unshift({ label: _label, slug: url });
    }

    return links;
  }

  return (
    <Layout links={getLinks(category_group)} location={location}>
      {/* <h1 className="title-seo" hidden>
        {h1_header}
      </h1> */}
      <section id={uid} className={`post -single ${uid} ${type}`}>
        <ScliceZone
          sliceZone={body}
          post={prismicBlogPostNew}
          links={getLinks(category_group)}
          tags={tags}
          latestPosts={latestPosts}
        />
      </section>
    </Layout>
  );
});

export default SingleTempale;

export const query = graphql`
  query PostTemplateQuery($id: String!, $uid: [String] = "") {
    prismicBlogPostNew(id: { eq: $id }) {
      uid
      url
      tags
      type
      _previewable
      data {
        published_date(formatString: "D MMM, yy")
        post_name
        meta_title
        meta_description
        h1_header
        featured_image {
          alt
          gatsbyImageData
        }
        category_group {
          category {
            type
            link_type
            url
            uid
            document {
              ... on PrismicBlogCategory {
                data {
                  category_name
                }
              }
              ... on PrismicBlogBrand {
                data {
                  brand_name
                }
              }
              ... on PrismicBlogConcern {
                data {
                  concern_name
                }
              }
              ... on PrismicBlogIngredient {
                data {
                  ingredient_name
                }
              }
            }
          }
        }
        author {
          url
          uid
          type
          link_type
          document {
            ... on PrismicBlogAuthor {
              data {
                position
                name
                headshot {
                  gatsbyImageData
                }
                description
              }
            }
          }
        }
        body {
          ... on PrismicSliceType {
            slice_type
          }
          ...BlogPostNewDataBodyPromotionalBanner
          ...BlogPostNewDataBodySingleShopifyProduct
          ...BlogPostNewDataBodyTextBlock
          ...BlogPostNewDataBodyPostContentFromWpBlog
          ...BlogPostNewDataBodyEmbed
        }
      }
    }

    allPrismicBlogPostNew(
      sort: { fields: last_publication_date, order: DESC }
      limit: 3
      filter: { uid: { nin: $uid } }
    ) {
      edges {
        node {
          data {
            published_date(formatString: "D MMM, yy")
            secondary_title
            post_name
            featured_image {
              alt
              gatsbyImageData
            }
            category_group {
              category {
                document {
                  ... on PrismicBlogCategory {
                    data {
                      category_name
                    }
                  }
                }
                uid
                type
                link_type
                url
              }
            }
          }
          uid
        }
      }
    }
  }
`;
